import { SVGProps, Ref, forwardRef } from 'react'

const SvgChevronRight = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path stroke="currentColor" strokeWidth={2} d="M7.85 18.65 15 11.5 7.85 4.35" />
  </svg>
)

const ForwardRef = forwardRef(SvgChevronRight)
export default ForwardRef
